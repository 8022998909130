import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DownloadIcon from '../../Icons/Download'
import CheckIcon from '@material-ui/icons/Check'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import { downloadFile } from '../../../api/downloadManager'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton'

import { useTranslation } from 'react-i18next';
import filesize from 'filesize'
import { Tooltip } from '@material-ui/core'
import { useFileContext } from '../provider/fileBrowserContext'

const styles = theme => ({
    nested: {
        paddingLeft: props => props.level * theme.spacing(4),
        flexWrap: 'nowrap',
        alignItems: 'flex-start'
    },
    progress: {
        width: '200px',
        fontStyle: 'italic'
    },
    fileIcon: {
        minWidth: theme.spacing(5),
        fontSize: '1.8rem'
    },
    fileName: {
        wordBreak: 'break-all'
    },
    successIcon: {
        color: green[400]
    },
    downloadedByContainer: {
        maxWidth: 600,
        wordBreak: 'break-all'
    }
});


const FileItem = ({item, level}) => {
    const classes = makeStyles(styles)({level})
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [donwloadProgress, setDonwloadProgress] = useState(0)
    const [donwloadStatus, setDonwloadStatus] = useState('')
    const { getFileStatus, setFileStatus } = useFileContext()
    
    const fileStatus = getFileStatus(item)

    const handleDownloadProgress = progress => {
        const newProgress = Math.round(progress.loaded * 100 / progress.total)
        if (donwloadProgress !== newProgress) {
            setDonwloadStatus(`${filesize(progress.loaded, {round: 1})} ${t('of')} ${filesize(progress.total, {round: 1})}`)
            setDonwloadProgress( Math.round(progress.loaded * 100 / progress.total))
        }
    }

    const handleFileDownload = () => {
        if (fileStatus === 'downloading'){
            //do not initiate new download if current is still in progress
            return;
        }

        setFileStatus(item, 'downloading')
        downloadFile(item.path, handleDownloadProgress)
            .then((response) => {
                const blob = new Blob([response.data])
                // MS Edge
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { 
                    window.navigator.msSaveOrOpenBlob(blob, item.name)
                } else {
                    const url = window.URL.createObjectURL(blob)
                    
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', item.name)
                    
                    document.body.appendChild(link)
                    link.click()
                    
                    window.URL.revokeObjectURL(url)
                    link.remove()
                }

                setFileStatus(item, 'success')
                setDonwloadProgress(0)
                setDonwloadStatus('')
            })
            .catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' })
                setFileStatus(item, 'error')
                setDonwloadProgress(0)
                setDonwloadStatus('')
            })
    }

    const getStatus = () => {
        switch (fileStatus) {
            case 'downloading': 
                return <div className={classes.progress}>
                    <Typography align='center' color='textSecondary' >{donwloadStatus}</Typography>
                    <LinearProgress value={donwloadProgress} variant='determinate' />
                </div>
            case 'success': 
                return <>
                <CheckIcon className={classes.successIcon} />
                <Typography  component="span"
                    variant="body2"
                    color="textSecondary">
                    {t('Successfully downloaded')}
                </Typography>
                </>
            case 'error': 
                return <Typography  component="span"
                    variant="body2"
                    color="error">
                    {t('Error downloading')}
                </Typography>
            case 'idle':
            default:
                return null;
        }
    }

    const getDownloadedBy = () => {
        let result = ''
        if (item.downloadedBy && item.downloadedBy.length > 0) {
            result = t('downloaded by ') + item.downloadedBy.join(', ')
        }

        return result
    }

    const uiStatus = getStatus()
    const downloadedBy = getDownloadedBy()
    
    return <ListItem button className={classes.nested} key={item.name} onClick={handleFileDownload}>
            <ListItemIcon className={classes.fileIcon}>
                <Tooltip title={t('Click to download')}>
                    <IconButton>
                    <DownloadIcon fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            
            <ListItemText primary={
                <React.Fragment>
                    <span className={classes.fileName}>{item.name}</span>
                    <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary">
                    {` (${filesize(item.fileSize, {round: 1})})`}
                    </Typography>
              </React.Fragment>} secondary={downloadedBy} classes={{secondary: classes.downloadedByContainer}}/>
            {uiStatus}
        </ListItem>
}

export default FileItem