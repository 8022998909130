import React from 'react'
import Layout from './layout/Layout'
import './i18n/i18n'
import AuthProvider from './components/Auth/provider/Auth'
import TelemetryProvider from './components/Telemetry/Telemetry'
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from './theme/themeProvider'
import SnackbarProvider  from './components/Snackbar/Snackbar'


const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <TelemetryProvider>
                    <ThemeProvider>
                        <SnackbarProvider>
                            <Layout />
                        </SnackbarProvider>
                    </ThemeProvider>
                </TelemetryProvider>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default App