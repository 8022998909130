import { lib as CryptoJsLib, enc as CryptoJsEnc, algo as CryptoJsAlgo } from 'crypto-js'
import polly from 'polly-js'
import { splitByRanges, concatArrayBuffers } from './utils'
import { getFilePart, getFile, getFileMetadata, notifyDownload } from './fileShareApi'

const { WordArray } = CryptoJsLib

const LARGE_FILE_LIMIT = 100 * 1024 * 1024
export const downloadFile = (path, progressCallback) => {
    return getFileMetadata(path).then(metadata => {
        if (metadata.size > LARGE_FILE_LIMIT) {
            // download by parts
            return getFileByParts(path, metadata, progressCallback)
        } else {
            // download as single blob
            return getFile(path, progressCallback)
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            throw err.response.data
        }
        
        throw err
    })
}

const getFileByParts = (path, {size: totalFileSize, content_hash}, progressCallback) => {
    const MIN_PARALEL_DOWNLOADS = 3 // splitByRanges can return more if file part size excesess limit
    const ranges = splitByRanges(totalFileSize, MIN_PARALEL_DOWNLOADS)

    const partsProgress = []
    const partDownloadProgress = index => progressObejct => {
        partsProgress[index] = progressObejct.loaded
        const totalLoaded = partsProgress.reduce((a, b) => a + b, 0) // sum of the elements in array
        progressCallback({
            loaded: totalLoaded,
            total: totalFileSize
        })
    }
    // try to retry 2 times each part download if it fails
    const downloadPromises = ranges.map((range, index) => polly()
        .retry(2)
        .executeForPromise(() => getFilePart(path, `bytes=${range.start}-${range.end}`, partDownloadProgress(index)))
    )

    return Promise.all(downloadPromises)
        .then(httpPromiseResults => {
            const md5Algo = CryptoJsAlgo.MD5.create()
            for (let i = 0; i < httpPromiseResults.length; i++) {
                const arr = WordArray.create(httpPromiseResults[i].data)
                md5Algo.update(arr)
            }
            const hash = md5Algo.finalize().toString(CryptoJsEnc.Base64)

            if (content_hash && content_hash !== hash) {
                console.log(`Expected: ${content_hash}. Actual: ${hash}`)
                throw new Error("Downloaded file was corrupted. Please try again")
            }

            notifyDownload(path)

            return { data: concatArrayBuffers(httpPromiseResults.map(value => value.data)) }
        })
}