import React from 'react'
import { useAuthContext } from './provider/authContext'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { useTranslation } from 'react-i18next'

const RedirectCallback = () => {
    const { signinRedirectCallback } = useAuthContext()
    const { t } = useTranslation()

    signinRedirectCallback()
    
    return <Container maxWidth="lg" style={{marginTop: 20}}>
        <Typography align="center" variant="h5" color="primary" >
            {t('Loading...')}
        </Typography>    
    </Container>
}

export default RedirectCallback