import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import CloseIcon from '@material-ui/icons/Close'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PersonIcon from '@material-ui/icons/Person'
import ProgressButton from '../../UI/ProgressButton'

import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import { deleteFolder } from '../../../api/fileShareApi'
import { useSnackbar } from 'notistack'

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1) / 2,
        top: theme.spacing(1) / 2,
        color: theme.palette.grey[500],
    },
    cancelButtonBottom:{
        backgroundColor:'#F6F6F4',
        paddingLeft:'1rem',
        paddingRight:'1rem',
        color:'333',
        '&:hover':{
            backgroundColor:'#00B9F6',
            color:'white'
        }
    },
    deleteButtonBottom:{
        backgroundColor:'#FF9600',
        paddingLeft:'1rem',
        paddingRight:'1rem',
        color:'white',
        '&:hover':{
            backgroundColor:'#FAC622'
        }
    }
})

const DeleteFolder = ({ folder, open, onClose, onDelete }) => {
    const classes = makeStyles(styles)()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [deleting, setDeleting] = useState(false)

    const handleDeleteAction = () => {
        setDeleting(true)
        deleteFolder(folder.path).then(success => {
            onDelete(folder)
            handleCloseAction()
        }).catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' })
            setDeleting(false)
        })
    }

    const handleCloseAction = () => {
        setDeleting(false)
        onClose()
    }

    const sharedMembers = folder.sharedWith && folder.sharedWith.length > 0 && 
        <List dense subheader={
            <ListSubheader component="div" id="nested-list-subheader" disableGutters disableSticky color='primary'>
                {t('This folder is shared with')}:
            </ListSubheader>
        }>
            {folder.sharedWith.map(member => <ListItem disableGutters key={member.email}>
                    <ListItemAvatar>
                        <Avatar><PersonIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={member.email} secondary={t('readonly')} />
                </ListItem>
            )}
        </List>
    

    return (
        <Dialog 
            onClose={handleCloseAction} 
            aria-labelledby="simple-dialog-title" 
            open={open} 
            disableBackdropClick
            maxWidth='sm'
            fullWidth={true}>
                
            <DialogTitle id="simple-dialog-title" disableTypography>
                <Typography variant='h5' color='primary'>
                    {t('Delete')} "{folder.name}"
                </Typography>

                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseAction}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <Trans i18nKey="DeleteConfirmation" />
                </DialogContentText>
                {sharedMembers}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAction} className={classes.cancelButtonBottom} >
                    {t('Cancel')}
                </Button>
                <ProgressButton progress={deleting}>
                    <Button onClick={handleDeleteAction} className={classes.deleteButtonBottom} disabled={deleting}>
                        {t('Delete')}
                    </Button>
                </ProgressButton>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteFolder;