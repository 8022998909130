import React from "react"

import { useAuthContext } from './provider/authContext'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
    button: {
        color: 'white',
        backgroundColor:'#005591',
        '&:hover':{
            backgroundColor:'#00B9F6'
        }
    },
})
const Login = () => {
    const classes = makeStyles(styles)()
    const { t } = useTranslation();
    const { signinRedirect, handleAuthenticating } = useAuthContext();

    const handleButtonClick = () => {
        handleAuthenticating()
        signinRedirect()
    }

    return (
        <Button variant="contained" className={classes.button} onClick={handleButtonClick}>
            {t('Login')}
        </Button>
    )
}

export default Login