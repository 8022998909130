import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import DialogContentText from '@material-ui/core/DialogContentText'

import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import copy from 'clipboard-copy'
import { getApiKey } from '../../../api/fileShareApi'

const styles = theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1) / 2,
      top: theme.spacing(1) / 2,
      color: theme.palette.grey[500],
    },
    dialogActions: {
        paddingLeft: 0,
        paddingRight: 0
    },
    generateButton: {
        width: '100%',
        '&:hover':{
            backgroundColor:'#00B9F6'
        }
    },
    attention: {
        marginTop: theme.spacing(1)
    },
    textAreaWrapper: {
        position: 'relative',
        '&:hover $copyToClipboard': {
            display: 'block'
        }
    },
    textArea:{
        color: theme.palette.text.secondary
    },
    copyToClipboard: {
        display: 'none',
        position: 'absolute',
        right: -theme.spacing(1),
        bottom: theme.spacing(1) / 2,
        color: theme.palette.grey[500],
    }
})

const Settings = ({open, onClose}) => {
    const classes = makeStyles(styles)()
    const { t } = useTranslation()

    const [apiKey, setApiKey] = useState('')
    const [loading, setLoading] = useState(false)

    const handleGenerateKeyAction = () => {
        if (loading){
            return
        }

        setLoading(true)
        getApiKey().then(apiKey => {
            setLoading(false)
            setApiKey(apiKey)
        })
        
    }

    const handleDialogCloseAction = (event) => {
        setApiKey('')
        onClose(event)
    }

    const handleCopyToClipboard = () => {
        copy(apiKey)
    }

    return (
        <Dialog 
            onClose={handleDialogCloseAction} 
            open={open} 
            maxWidth='sm'
            fullWidth={true}
            disableBackdropClick>
            <DialogTitle disableTypography>
                <Typography variant='h5' color='primary'>
                    {t('Settings')}
                </Typography>
                
                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleDialogCloseAction}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.textAreaWrapper}>
                    <TextField 
                        fullWidth 
                        multiline 
                        rows={5} 
                        value={apiKey}
                        readOnly 
                        label={t('Generate an API-Key for usage in your Scan-software.')}
                        InputProps={{
                            className: classes.textArea
                        }}/>
                    <Tooltip title={t('Copy to Clipboard')} placement='top-end'>
                        <IconButton className={classes.copyToClipboard} onClick={handleCopyToClipboard}>
                            <FileCopyIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                     

                <DialogContentText variant='subtitle2' className={classes.attention}>
                    <Trans i18nKey="SettingsDialogAttention">
                        Attention:<br/>
                        Generating a new API Key invalidates your existing API Key. 
                    </Trans>
                </DialogContentText>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleGenerateKeyAction} color="primary" variant="contained" className={classes.generateButton}>
                        {t('Generate new API Key')}
                    </Button> 
                </DialogActions>
            </DialogContent>

        </Dialog>
    );
}

export default Settings;