import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import i18n from '../../i18n/i18n';
import { languages, findLanguageByCode } from '../../i18n/supportedLanguages'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
    langIcon: {
        marginRight: theme.spacing(1)
    },
    languageButton: {
        textTransform: 'uppercase',
        fontSize:'1.2rem',
        fontWeight:'100',
        borderBottom:'1px solid #005591',
        color:'#005591',
        marginRight:'1rem',
        '&:hover':{
            color:'#00B9F6',
            cursor:'pointer',
            borderBottom:'1px solid #00B9F6',
        }
    }
});

const LanguageSwitcher = () => {
    const [, setCurrentLanguage] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const classes = makeStyles(styles)()

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }
    
    React.useEffect(() => {
        const initialLanguage = findLanguageByCode(i18n.language)
        setCurrentLanguage(initialLanguage.name)
    }, []);

    const handleLanguageChange = lng => {
        setCurrentLanguage(lng.name);
        i18n.changeLanguage(lng.code)
        handleClose()
    };

    const openLanguageMenu = Boolean(anchorEl);

    return <>
        <Tooltip title={t('Change Language')}>
            <span 
                onClick={handleMenu} className={classes.languageButton}>
                {i18n.language.substr(0,2)}
            </span>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            open={openLanguageMenu}
            onClose={handleClose}
        >
            {languages.map(lng => <MenuItem 
                onClick={() => handleLanguageChange(lng)}
                key={lng.name}>{lng.name}
                </MenuItem>)}
        </Menu>
    </>

}

export default LanguageSwitcher