import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import FolderSharedOpenIcon from '@material-ui/icons/FolderSharedOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next';
import FileItem from '../FileItem/FileItem'
import FadeInOutIcon from '../../UI/FadeInOutIcon'
import { sortFileBrowser, toLocalDate } from '../utils/utils'
import { listFolder } from '../../../api/fileShareApi'
import { useFileContext } from '../provider/fileBrowserContext';
import { Icon, Typography } from '@material-ui/core';


const styles = theme => ({
    nested: {
        paddingLeft: props => props.level * theme.spacing(4),
    },
    fodlerName: {
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    sharedBy: {
        maxWidth: 600,
        wordBreak: 'break-all'
    }

});

const SharedFolderItem = ({ folder,
    level
}) => {
    const classes = makeStyles(styles)({ level })
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { getFolderItems, setFolderItems, getFolderOpenStatus, toggleFolderOpenStatus } = useFileContext()
    const folderItems = getFolderItems(folder)
    const folderOpen = getFolderOpenStatus(folder)

    const handleOpenFolder = () => {
        if (!folderItems) {
            setLoading(true);
            listFolder(folder.path).then(items => {
                const sorted = items.sort(sortFileBrowser);

                setFolderItems(folder, sorted)
                toggleFolderOpenStatus(folder)
                setLoading(false)
            }).catch(err => {
                let errorMessage = err.message
                if (err.response && err.response.data) {
                    errorMessage = err.response.data.message
                }
                enqueueSnackbar(errorMessage, { variant: 'error' })
                setLoading(false)
            })
        } else {
            toggleFolderOpenStatus(folder)
        }

    }

    const folderItemsComponents = folderItems ? folderItems.map(item => {
        if (item.type === 'file') {
            return <FileItem item={item} key={item.name} level={level + 1}></FileItem>
        } else {
            return <SharedFolderItem folder={item} key={item.name} level={level + 1} />
        }
    }) : null
    return (
        <>
            <ListItem button disableRipple onClick={handleOpenFolder} className={classes.nested}>
                <ListItemIcon className={classes.folderIcon}>
                    {loading ? <CircularProgress size={20} /> :
                        <FadeInOutIcon fadeIn={folderOpen}
                            fadeInIсon={<FolderSharedOpenIcon color='primary' fontSize='large' />}
                            fadeOutIсon={<FolderSharedIcon color='primary' fontSize='large' />} />
                    }
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.fodlerName, secondary: classes.sharedBн }}
                    primaryTypographyProps={{ variant: 'h6' }}
                    primary={folder.name}
                    secondary={

                        <React.Fragment>
                            <Typography component="span" variant='body2'> {t('shared by')} {folder.owner}</Typography>
                            <Typography component="span" variant='body2' className='folder-date'> &raquo;  {toLocalDate(folder.uploadedTimestamp)}</Typography>
                        </React.Fragment>}
                />

                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={handleOpenFolder}>
                        {folderOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={folderOpen} timeout="auto" unmountOnExit >
                <List component="div" disablePadding>
                    {folderItemsComponents}
                </List>
            </Collapse>
        </>
    )
}

export default SharedFolderItem