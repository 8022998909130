import i18n from '../../../i18n/i18n';
import { findLocaleByCode } from '../../../i18n/supportedLanguages';

export const sortFileBrowser = (item1, item2) => {
    return 0;
}

export const toLocalDate = (utcDate) => {
    var locale = findLocaleByCode(i18n.language).replace('_','-');
    utcDate = utcDate+'z';
    var localDate = new Date(utcDate).toLocaleString(locale, {
        dateStyle:"full",
        timeStyle:"short"
    });

    return localDate;
}

export const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/