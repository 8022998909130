import React from 'react'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles';

import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { languageResources, findLocaleByCode } from '../../i18n/supportedLanguages';


const useStyles = makeStyles(theme => ({
    footer: {
        marginTop: 'auto',
        padding: theme.spacing(3, 0),
        backgroundColor: theme.palette.background.paper
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 3)
    },
    languageSwitcher: {
        flexBasis: '20%'
    },
    linksContainer: {
        flexBasis: '60%',
        textAlign: 'center'
    },
    spaceHolder: {
        flexBasis: '20%'
    }
}))

var getTermsOfUseLink = () => {        
    return 'https://www.ivoclar.com/' + findLocaleByCode(i18n.language) + '/legal/terms-of-use-ivcloud'
}

const Footer = ({ match }) => {
    const classes = useStyles()
    const { t } = useTranslation();

    return (


        <footer className={classes.footer}>
            <div className={classes.footerContainer}>
                <div className={classes.spaceHolder} />
                <div className={classes.linksContainer}>
                    <Link to={{ pathname: getTermsOfUseLink() }} variant="subtitle1" color="textSecondary" target="_blank" component={RouterLink}>
                        {t('Terms of use')}
                    </Link>
                </div>
                <div className={classes.spaceHolder} />
            </div>
        </footer>
    )
}

export default Footer