import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../Auth/provider/authContext'
import { withRouter } from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

const Logout = ({history}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { isAuthenticated, signoutRedirectCallback } = useAuthContext()

    signoutRedirectCallback()
    
    return !isAuthenticated() && <Container maxWidth='md' className={classes.root}>
            <Typography align="center" variant="h5" color="textSecondary">
                {t('You have successfully logged out of your account')}
            </Typography>
    </Container>
}

export default withRouter(Logout)