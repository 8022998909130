import React from 'react'
import { SnackbarProvider as NSnackbarProvider}  from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const SnackbarProvider = ({children}) => {
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => { 
        // this will be renamed to closeSnackbar in newer version of notistack 
        notistackRef.current.handleDismissSnack(key);
    }

    return (
        <NSnackbarProvider ref={notistackRef} maxSnack={3} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }} hideIconVariant={true} action={(key) => (
            <IconButton onClick={onClickDismiss(key)} color={'inherit'} size={'small'}>
                <CloseIcon/>
            </IconButton>
        )}>
            {children}
        </NSnackbarProvider>
    )
}

export default SnackbarProvider