import translationEN from '../assets/locales/en/translation.json'
import translationDE from '../assets/locales/de/translation.json'
import translationES from '../assets/locales/es/translation.json'
import translationFR from '../assets/locales/fr/translation.json'
import translationIT from '../assets/locales/it/translation.json'
import translationPT from '../assets/locales/pt/translation.json'

export const languages = [{
    code: 'en',
    name: 'English'
}, {
    code: 'pt',
    name: 'Portuguese'
}, {
    code: 'de',
    name: 'Deutsch'
}, {
    code: 'es',
    name: 'Español'
}, {
    code: 'it',
    name: 'Italian'
}, {
    code: 'fr',
    name: 'Français'
}]

export const languageResources = {
    en: {
        translation: translationEN,
        locale: 'en_US'
    },
    de: {
        translation: translationDE,
        locale: 'de_DE'
    },
    es: {
        translation: translationES,
        locale: 'es_ES'
    },
    fr: {
        translation: translationFR,
        locale: 'fr_FR'
    },
    it: {
        translation: translationIT,
        locale: 'it_IT'
    },
    pt: {
        translation: translationPT,
        locale: 'pt_PT'
    },
}

export const findLanguageByCode = code => {
    return languages.find(lang => code.startsWith(lang.code)) || languages[0]
}

export const findLocaleByCode = code => {
    if(code === null){
        code = "en";
    }
    var locale = "en_EN";
    var language = languageResources[code];
    if (language) {
        locale = language.locale;
    }
    return locale;
}