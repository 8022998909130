import axios from './axios'
import { transformResponseError } from './utils'

export const listFolder = path =>
    axios.get('/v1/ListFolder', {
        params: { path }
    }).then(({ data }) => data.data)

export const listMyFolders = userId => 
    listFolder(userId).catch(err => {
        // handle home folder does not exists error as empty list
        if (err.response.status === 404){
            return [] 
        }
        throw err
    })

export const listFoldersSharedWithMe = () =>
    axios.get('/v1/GetFoldersSharedWithMe')
        .then(({ data }) => data.data)

export const deleteFolder = (path) =>
    axios.post('/v1/Delete', null, {
        params: { path }
    })
    .then(({ data }) => data.data)
    .catch(err => {
        throw err.response.data
    })

export const shareFolder = (path, memberEmail) =>
    axios.post('/v1/ShareFolder', null, {
        params: { path, memberEmail }
    }).then(({ data }) => data.data)

export const unshareFolder = (path, memberEmail) =>
    axios.post('/v1/UnshareFolder', null, {
        params: { path, memberEmail }
    }).then(({ data }) => data.data)

/**
 * @deprecated Please use OpenID authorization. This endpoint was added just for testing purposes
 */
export const getToken = email => {
    console.warn("\"getToken\" is deprecated!");
    return axios.post('/v1/getToken', null, {
        params: { email },
        headers: { 'x-api-key': '123456789' }
    }).then(response => response.data)
}

export const getAccountInfo = () =>
    axios.get('/v1/GetAccountInfo').then(({ data }) => data)

export const getApiKey = () =>
    axios.get('/v1/GetApiKey').then(({ data }) => data)

export const notifyDownload = (path) =>
    axios.post('/v1/NotifyDownload', null, {
        params: { path }
    })

export const getFile = (path, onDownloadProgress) =>
    axios.get('/v1/GetFile', {
        params: { path },
        responseType: 'arraybuffer',
        transformResponse: [...axios.defaults.transformRequest, transformResponseError],
        onDownloadProgress: onDownloadProgress
    })

export const getFilePart = (path, range, onDownloadProgress) =>
    axios.get('/v1/GetFilePart', {
        params: { path },
        headers: { range },
        responseType: 'arraybuffer',
        transformResponse: [...axios.defaults.transformRequest, transformResponseError],
        onDownloadProgress: onDownloadProgress
    })

export const getFileMetadata = (path) =>
    axios.get('/v1/GetFileMetaData', {
        params: { path }
    }).then(({ data }) => data.data)