import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'

const styles = () => ({
    iconWrapper: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        top: 0,
        left: 0
    }
});

const FadeInOutIcon = ({fadeIn, fadeInIсon, fadeOutIсon}) => {
    const classes = makeStyles(styles)()

    return <div className={classes.iconWrapper}>
        <Fade in={fadeIn}>
            <div>
                {fadeInIсon}
            </div>
        </Fade>
        <Fade in={!fadeIn} >
            <div className={classes.icon}>
                {fadeOutIсon}
            </div>
        </Fade>
    </div>
}

export default FadeInOutIcon