import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Download = React.forwardRef((props, ref) => {
  return (
    <SvgIcon {...props}>
      <path d="M22.195 9.899A4.456 4.456 0 0019.5 9a.5.5 0 010-1c.13 0 .257.015.385.028l.151.016a.542.542 0 00.048.002h.02a.5.5 0 00.409-.788 7.982 7.982 0 00-14.02-1.884.5.5 0 00.307.778 6.626 6.626 0 012.596 1.145c.106.08.176.196.195.328a.495.495 0 01-.095.372.508.508 0 01-.697.102A5.568 5.568 0 005.5 7C2.468 7 0 9.467 0 12.5S2.468 18 5.58 18h.019a.5.5 0 00.457-.703A6.164 6.164 0 016 16.5c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5c0 .28-.023.576-.074.93a.5.5 0 00.495.57h.079c2.481 0 4.5-2.019 4.5-4.5 0-1.428-.658-2.74-1.805-3.601z" /><path d="M12.5 11C9.468 11 7 13.467 7 16.5S9.468 22 12.5 22s5.5-2.467 5.5-5.5-2.468-5.5-5.5-5.5zm2.354 6.854l-2 2a.498.498 0 01-.708 0l-2-2a.5.5 0 11.708-.708L12 18.293V13.5a.5.5 0 111 0v4.793l1.146-1.147a.5.5 0 11.708.708z" />
    </SvgIcon>
  )
});

export default Download;