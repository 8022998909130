import { IDENTITY_CONFIG, IDENTITY_LOGOUT_URL } from "./authConfig";
import { UserManager, WebStorageStateStore } from "oidc-client";
import jwt from 'jwt-decode'
import axios from '../api/axios'
import { loginPingback } from '../api/userApi'
import i18n from '../i18n/i18n';
import { findLocaleByCode } from '../i18n/supportedLanguages';

export default class AuthService {
    UserManager
    idToken
    history
    userId

    constructor(history) {
        this.history = history
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        })

        try {
            if(!window.sessionInitialized){
                this.refreshTokenSync();

                setInterval(() => {
                    this.refreshToken();
                }, 180000);
    
                window.sessionInitialized = true;
            }
        } catch (error) {
            console.log(error);
        }

        this.UserManager.events.addUserLoaded(user => {
            this.setIdToken(user.id_token)
            
            localStorage.setItem("user_profile", JSON.stringify(user.profile))
            localStorage.setItem("refresh_token", user.refresh_token);
            this.setUserInfo({
                idToken: this.idToken
            })

            this.setIdToken(user.id_token);
            loginPingback()
            
            if (this.history.location.pathname.indexOf("/redirect") !== -1) {
                this.history.replace('/')
            }
        })
        this.UserManager.events.addSilentRenewError(e => {
            console.log("silent renew error", e.message)
        })

        this.UserManager.events.addAccessTokenExpired(() => {
            
        })

        this.userId = localStorage.getItem('userId')
        this.setIdToken(localStorage.getItem('id_token'))
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            ""
        }).catch(err => {
            console.error("sign-in error", err)
            this.history.replace('/')
        })
    }

    getUser = async () => {
        const user = await this.UserManager.getUser()
        if (!user) {
            return await this.UserManager.signinRedirectCallback()
        }
        return user
    }

    setUserInfo = authResult => {
        const data = jwt(authResult.idToken)
        this.setSessionInfo(authResult)
        this.setUser(data)
    };

    signinRedirect = () => {
        this.UserManager.signinRedirect({"ui_locales":findLocaleByCode(i18n.language)})
    };

    setUser = data => {
        localStorage.setItem("userId", data.sub)
        this.userId = data.sub
    }

    getUserProfile = () => JSON.parse(localStorage.getItem("user_profile"))

    setSessionInfo(authResult) {
        localStorage.setItem("id_token", authResult.idToken)
    }

    setIdToken(idToken) {
        console.log("Set session info...")
        
        this.idToken = idToken
        axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;

        var userProfile = localStorage.getItem("user_profile");

        if(userProfile){
            userProfile = JSON.parse(userProfile);
            axios.defaults.headers.common['email'] = userProfile.email;
            axios.defaults.headers.common['dataCenter'] = userProfile.dataCenter;
            axios.defaults.headers.common['firstName'] = encodeURIComponent(userProfile.firstName);
            axios.defaults.headers.common['lastName'] = encodeURIComponent(userProfile.lastName);
            axios.defaults.headers.common['locale'] = userProfile.locale;

            console.log("Headers are set.")
        }
    }

    refreshToken(){
        var saveAndSetData = this.saveAndSetTokens;
        var refresh_token = localStorage.getItem("refresh_token");

        if(!refresh_token) return;

        axios.post("/v1/users/refreshtoken?refreshToken="+refresh_token,null)
        .then(function(data){
            console.log("Refresh tokens are equal: "+(refresh_token === data.data.refresh_token))

            saveAndSetData(data.data);
        })
        .catch(err => {
            console.log(err);
            //window.location.reload();
        })
    }

    refreshTokenSync(){
        var refresh_token = localStorage.getItem("refresh_token");
        var id_token = localStorage.getItem("id_token");
        console.log("Initial token refresh... "); 
        
        if(!refresh_token || !id_token) return;

        var req = new XMLHttpRequest();  
        var saveAndSetData = this.saveAndSetTokens;
        req.open('POST', process.env.REACT_APP_API_BASE_URL+"/v1/users/refreshtoken?refreshToken="+refresh_token, false);
        req.setRequestHeader("Authorization", "Bearer "+id_token)
        req.onload = function() {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    var data = JSON.parse(req.responseText);
                    saveAndSetData(data);
                } else {
                    console.error(req.statusText);
                    localStorage.removeItem("id_token");
                    window.location.reload();
                }
            }
        };
        req.send();
        console.log("Initial token refresh finished."); 
    }

    saveAndSetTokens(data){
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.id_token}`;
        localStorage.setItem("id_token",data.id_token);
        localStorage.setItem("refresh_token",data.refresh_token);
    }

    isAuthenticated = () => {
        const id_token = localStorage.getItem("id_token")
        return !!id_token
    }

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then(user => {
                console.log("signed in", user)
            })
            .catch(err => {
                console.log(err)
                this.signinRedirect()
            })
    }
    

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback()
    }

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest()
    }

    logout = () => {
        window.location.replace(IDENTITY_LOGOUT_URL)
        this.UserManager.clearStaleState()
    }

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear()
            this.history.replace('/')
        })
        this.UserManager.clearStaleState()
    }
}