import React from 'react'
import { Toolbar,  
    Footer,
    RedirectCallback, 
    PrivateRoute, 
    Logout, 
    SilentRenewCallback, 
    FileBrowser,
    FileContextProvider} from '../components';

import { Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    wrapper: {
        //background: `linear-gradient(to bottom, #F4F4F4 0%, #c1c1c1 60%, #F4F4F4 100%)`,
        backgroundColor:'#FFFFFF',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        flex: '1 0 auto',
        display: 'flex',
        paddingTop: theme.spacing(3)
    },
    appBarSpacer: theme.mixins.toolbar,
})

const Layout = () => {
    const classes = makeStyles(styles)()

    return <div className={classes.root}>
        <Toolbar />
        <div className={classes.appBarSpacer} />
        <div className={classes.wrapper}>
            <FileContextProvider>
                <Switch>
                    <PrivateRoute exact path="/" component={FileBrowser} />
                    <Route exact path="/redirect" component={RedirectCallback} />
                    <Route exact path="/redirect-silent" component={SilentRenewCallback} />
                    <Route exact path="/logout" component={Logout} />
                </Switch>
            </FileContextProvider>
        </div>
        <Footer />
    </div>
}


export default Layout;