import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import FolderItem from './FolderItem/FolderItem'
import FileItem from './FileItem/FileItem'
import SharedFolderItem from './FolderItem/SharedFolderItem'
import { useFileContext } from './provider/fileBrowserContext'
import { useAuthContext } from '../Auth/provider/authContext'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    root: {
        marginTop: theme.spacing(2)
    },
    loadingWrapper: {
        width: '100%',
        textAlign: 'center',
        minHeight: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }, 
    folderRoot: {
        margin: theme.spacing(2, 0)
    }
}));

const FileBrowser = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const authContext = useAuthContext()
    const {enqueueSnackbar } = useSnackbar()
    const message401 = t('You are not authorized to view list of files')
    const { handleFolderDeleteAction, handleFolderShareAction, hasFolders, myFolders, sharedWithMeFolders, loading, loadFolders, initialized } = useFileContext()  

    useEffect(() => {
        if (!initialized) {
            loadFolders(authContext.userId).catch(err => {
                if (err.response.status === 401) {
                    enqueueSnackbar(message401, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    })
                }
            })
        }
        
        // eslint-disable-next-line
    }, [authContext.userId])

    const folderItemsComponent = item => {
        if (item.type === 'file') {
            return <FileItem 
                item={item} 
                level={1} />
        } else {
            return <FolderItem 
                folder={item} 
                level={1} 
                onFolderDelete={handleFolderDeleteAction} 
                onFolderShare={handleFolderShareAction}/>
        }
    }

    let foldersContent
        const myFoldersComponents = myFolders.length > 0 &&
            <> 
                <Typography variant="h5" component="h3">
                    {t('My Files')}
                </Typography>
                {myFolders.map(item => 
                    <Paper square className={classes.folderRoot} key={item.name}>
                        {folderItemsComponent(item)}
                    </Paper>
                )}
            </>;

        const sharedFoldersComponents = sharedWithMeFolders.length > 0 &&
            <> 
                <Typography variant="h5" component="h3">
                    {t('Files shared with me')}
                </Typography>
                {sharedWithMeFolders.map(folder =>
                    <Paper square className={classes.folderRoot} key={folder.name}>
                        <SharedFolderItem
                            level={1}
                            folder={folder}/>
                    </Paper>
                )}
            </>;
        
        const foldersList = 
            <Box className={classes.root}>

                {hasFolders ? 
                    <>
                        {sharedFoldersComponents}
                        {myFoldersComponents} 
                    </> : 
                    <Typography align="center" variant="h5" color="primary">
                        {t('Your file storage is empty')}
                    </Typography>
                }
            </Box>
        foldersContent = loading ? <div className={classes.loadingWrapper}><CircularProgress size={72} /></div> : foldersList

    return (
        <Container className={classes.root}>
            {foldersContent}
        </Container>
    )
}

export default FileBrowser;