import React, { useState } from 'react'
import { FileProvider } from './fileBrowserContext'
import { listMyFolders, listFoldersSharedWithMe } from '../../../api/fileShareApi'
import { sortFileBrowser } from '../utils/utils'

const DEFAULT_STATE = {
    myFolders: [],
    sharedWithMeFolders: [],
    loading: false,
    initialized: false,
    folderItems: {},
    folderOpenStatus: {},
    fileStatus: {},
}

const FileContextProvider = ({ children }) => {
    const [state, setState] = useState(DEFAULT_STATE)

    const loadFolders = (userId) => {
        setState(prevState => ({...prevState, loading: true}));
        const promises = [listMyFolders(userId), listFoldersSharedWithMe()]

        return Promise.all(promises).then(([folders, sharedFolders]) => {
            setState(prevState => ({
                ...prevState,
                myFolders: folders.sort(sortFileBrowser),
                sharedWithMeFolders: sharedFolders.sort(sortFileBrowser),
                loading: false,
                initialized: true
            }))
        }).catch(err => {
            setState(prevState => ({
                ...prevState,
                initialized: true
            }))
            throw err
        })
    }

    const handleFolderDeleteAction = (deletedItem) => {
        const { myFolders: folders  } = state
        const indexToDelete = folders.findIndex(folder => folder.path === deletedItem.path)

        setState(prevState => ({
            ...prevState,
            myFolders: [
                ...folders.slice(0, indexToDelete),
                ...folders.slice(indexToDelete + 1)
            ]
        }))
    }

    const handleFolderShareAction = (folderItem) => {
        const { myFolders: folders } = state
        const updatedFolders = folders.map(folder => {
            if (folder.path !== folderItem.path) {
                return folder
            }

            return Object.assign({}, folder, {sharedWith: folderItem.sharedWith})
        })
        setState(prevState => ({
            ...prevState,
            myFolders: updatedFolders
        }))
    }

    const getFolderItems = (folder) => {
        return state.folderItems[folder.path]
    }
    
    const setFolderItems = (folder, folderItems) => {
        const newItems = {
            ...state.folderItems,
            [folder.path]: folderItems
        }
        state.folderItems[folder.path] = folderItems
        setState(prevState => ({
            ...prevState,
            folderItems: newItems
        }))
    }

    const getFileStatus = (file) => {
        return state.fileStatus[file.path]
    }
    
    const setFileStatus = (file, status) => {
        const newStatuses = {
            ...state.fileStatus,
            [file.path]: status
        }

        setState(prevState => ({
            ...prevState,
            fileStatus: newStatuses
            
        }))
    }

    const getFolderOpenStatus = (folder) => {
        return state.folderOpenStatus[folder.path]
    }
    
    const toggleFolderOpenStatus = (folder) => {
        const currentStatus = state.folderOpenStatus[folder.path]

        const newStatuses = {
            ...state.folderOpenStatus,
            [folder.path]: !currentStatus
        }

        setState(prevState => ({
            ...prevState,
            folderOpenStatus: newStatuses
        }))
    }

    const provider = {
        ...state,
        handleFolderDeleteAction,
        handleFolderShareAction,
        hasFolders: state.myFolders.length > 0 || state.sharedWithMeFolders.length > 0,
        getFolderItems,
        setFolderItems,
        getFolderOpenStatus,
        toggleFolderOpenStatus,
        getFileStatus,
        setFileStatus,
        loadFolders
    }

    return (
        <FileProvider value={provider}>
            {children}
        </FileProvider>
    )
}

export default FileContextProvider;