import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {languageResources as resources} from './supportedLanguages'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;