const AUTH_URL = process.env.REACT_APP_IDENTITY_AUTH_URL

export const IDENTITY_CONFIG = {
    authority: AUTH_URL, //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: process.env.REACT_APP_LOGIN_CALLBACK_URL, //The URI of your client application to receive a response from the OIDC provider.
    login: AUTH_URL + "/login",
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: process.env.REACT_APP_SILENT_RENEW_URL, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_URL, // (string): The OIDC post-logout redirect URI.
    response_type: "code",
    scope: "openid ivoclarconnect"
};

export const IDENTITY_LOGOUT_URL = process.env.REACT_APP_IDENTITY_LOGOUT_URL
export const IDENTITY_EDIT_PROFILE = process.env.REACT_APP_IDENTITY_EDIT_PROFILE
