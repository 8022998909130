import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import red from '@material-ui/core/colors/red'

const theme = createMuiTheme({
    // typography: {
    //     "fontFamily": "\"Open Sans\", sans-serif"
    // },
    palette: {
        primary: {
            main: '#005591'
        },
        secondary: red,
    },
});

const ThemeProvider = ({children}) => (
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
)

export default ThemeProvider