import React from 'react'
import { useAuthContext } from './provider/authContext'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { useTranslation } from 'react-i18next'

const SilentRenewCallback = () => {
    const { signinSilentCallback } = useAuthContext()
    const { t } = useTranslation()

    signinSilentCallback()
    
    return <Container maxWidth="lg" style={{marginTop: 20}}>
        <Typography align="center" variant="h5" color="primary" >
            {t('Loading...')}
        </Typography>    
    </Container>
}

export default SilentRenewCallback