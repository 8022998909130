import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Delete = React.forwardRef((props, ref) => {
  return (
    <SvgIcon {...props}>
      <path d="M19 3h-3V2c0-1.103-.897-2-2-2h-4C8.897 0 8 .897 8 2v1H5c-1.103 0-2 .897-2 2v1.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5V5c0-1.103-.897-2-2-2zm-9-1h4v1h-4V2zM19.5 8h-15a.5.5 0 00-.5.5V22c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V8.5a.5.5 0 00-.5-.5z"/>
    </SvgIcon>
  )
});

export default Delete;