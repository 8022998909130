import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Login from '../Auth/Login'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundImage: `url(ivcloudbg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        flexGrow: 1,
        display: 'block',
        justifyContent: 'center'
    },
    container: {
        padding: theme.spacing(3, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        paddingTop: '20px',
        height: '100px'
    },
    caption: {
        paddingTop: theme.spacing(2),
        color:'#005591',
        marginBottom:'2rem'
    },
    box:{
        marginLeft:'auto',
        width:'600px',
        marginTop:'100px',
        padding:'2rem',
        backgroundColor:'rgb(255,255,255,0.5)'
    },
    title:{
        marginBottom:'1rem'
    }
}))

const NotLoggedIn = () => {
    const classes = useStyles()
    const { t } = useTranslation();

    return <div className={classes.wrapper}>
        <Container maxWidth="lg" className={classes.container} >
            <Typography variant="h5" color="textSecondary" align='center' className={classes.caption}>
                
            </Typography>
            <div className={classes.box}>
            <Typography variant="h3" color="textSecondary" align='left' className={classes.title}>
                {t('Ivoclar Cloud')}
            </Typography>
            <p className={classes.caption}>
                {t('Our Ivoclar Cloud connects dental offices and dental labs in a new way. Share files easily and quickly with your partner and coordinate cases efficiently. Work hand in hand to envision a patient\'s new smile together.')}
            </p>
            <Login/>
            </div>
        </Container>
    </div>
    
}

export default NotLoggedIn;