import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import MuiToolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import LinearProgress from '@material-ui/core/LinearProgress'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo from '../../assets/img/logo.svg'
import { useAuthContext } from '../Auth/provider/authContext'
import Settings from './Dialog/Settings'
import { IDENTITY_EDIT_PROFILE } from '../../utils/authConfig'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import i18n from '../../i18n/i18n';
import { languageResources, findLocaleByCode } from '../../i18n/supportedLanguages';

const useStyles = makeStyles(theme => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#ffffff',
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarImageContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        //paddingTop: theme.spacing(1),
        paddingTop: '9px',
        paddingBottom: '0px !important',
        width: '128px',
        height: theme.spacing(7),
        [theme.breakpoints.up('xs')]: {
            height: theme.spacing(6),
        },
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(8),
        },
    },
    progressContainer: {
        flexGrow: 1,
        marginTop: 'auto'
    },
    actionsArea: {
        display: 'flex',
        alignItems: 'center'
    },
    welcome:{
        borderBottom:'1px solid #ECECEC',
        color:'#005591',
        fontSize:'0.8rem',
        height:'32px',
        lineHeight:'32px',
        paddingLeft:'24px'
    }
}));

const Toolbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const { t } = useTranslation();
    const { isAuthenticated, logout, getUserProfile, authenticating } = useAuthContext()

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleLogoutClick = () => {
        handleClose()
        logout()
    }

    const handleSettingsClick = () => {
        handleClose()
        setShowSettingsDialog(true)
    }

    const handleSettingsDialogHide = () => {
        setShowSettingsDialog(false)
    }

    const handleEditProfileClick = () => {
        window.location = IDENTITY_EDIT_PROFILE+"?ui_locales="+ findLocaleByCode(i18n.language);
    }

    const open = Boolean(anchorEl)
    const classes = useStyles()

    let actions //= <Login />

    if (isAuthenticated()) {
        actions = <>
            <IconButton aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit">
                <SettingsIcon fontSize="large" color="primary" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleSettingsClick}>{t('Settings')}</MenuItem>
                <MenuItem onClick={handleEditProfileClick}>{t('Edit my profile')}</MenuItem>
                <MenuItem onClick={handleLogoutClick}>{t('Log out')}</MenuItem>
            </Menu>
        </>
    }

    const navBarContent = authenticating ?
        <LinearProgress className={classes.progressContainer} /> :
        <>
            <div className={classes.toolbarImageContainer}>
                <Link to='/'>
                    <img src={logo} alt="Logo" className={classes.logo}></img>
                </Link>
            </div>
            <LanguageSwitcher />
            {actions}
        </>
    let welcomeText
    if (isAuthenticated()) {
        const { firstName, lastName } = getUserProfile()
        welcomeText = <>
                    {t('Welcome')}, {`${firstName} ${lastName}`}
                    </>
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" color="default" elevation={0} className={classes.appBar}>
                <div className={classes.welcome}>{welcomeText}</div>
                <MuiToolbar className={classes.toolbar} disableGutters={authenticating}>
                    {navBarContent}
                </MuiToolbar>
            </AppBar>
            <Settings open={showSettingsDialog} onClose={handleSettingsDialogHide}></Settings>
        </React.Fragment>
    )
}
export default Toolbar;