import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1),
        marginLeft: -theme.spacing(1),
    }
})

const ProgressButton = ({children, progress}) => {
    const classes = makeStyles(styles)();

    return <div className={classes.wrapper}>
        {children}
        {progress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
}

export default ProgressButton