import { useContext, createContext } from "react";

const fileContext = createContext({
    myFolders: [],
    loading: false,
    sharedWithMeFolders: [],
})

export const FileProvider = fileContext.Provider;
export const useFileContext = () => useContext(fileContext)
export default fileContext