import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FolderClosedIcon from '../../Icons/FolderClosed'
import FolderOpenIcon from '../../Icons/FolderOpen'
import DeleteIcon from '../../Icons/Delete'
import ShareIcon from '@material-ui/icons/Share'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import FileItem from '../FileItem/FileItem'
import FadeInOutIcon from '../../UI/FadeInOutIcon'
import { sortFileBrowser, toLocalDate } from '../utils/utils'
import { listFolder } from '../../../api/fileShareApi'
import DeleteFolderDialog from '../Dialogs/DeleteFolder'
import ShareFolderDialog from '../Dialogs/ShareFolder'
import { useFileContext } from '../provider/fileBrowserContext'
import { ListItemAvatar, Typography } from '@material-ui/core'


const styles = theme => ({
    nested: {
        paddingLeft: props => props.level * theme.spacing(4),
        '&:hover':{
            backgroundColor:'#FBFBFA'
        }
    },
    folderName: {
        color:'#005591',
        '&:hover': {
            color:'#00B9F6'
        }
    }
});

const FolderItem = ({ folder,
    level,
    onFolderDelete,
    onFolderShare
}) => {
    const classes = makeStyles(styles)({level})
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showShareDialog, setShowShareDialog] = useState(false)
    const { getFolderItems, setFolderItems, getFolderOpenStatus, toggleFolderOpenStatus } = useFileContext()
    
    const folderItems = getFolderItems(folder)
    const folderOpen = getFolderOpenStatus(folder)

    const handleOpenFolder = () => {
        if (!folderItems) {
            setLoading(true);
            listFolder(folder.path).then(items => {
                const sorted = items.sort(sortFileBrowser);

                setFolderItems(folder, sorted)
                toggleFolderOpenStatus(folder)
                setLoading(false)
            }).catch(err => {
                let errorMessage = err.message
                if (err.response && err.response.data) {
                    errorMessage = err.response.data.message
                }
                enqueueSnackbar(errorMessage, { variant: 'error' })
                setLoading(false)
            })
        } else { 
            toggleFolderOpenStatus(folder)
        }   
    }

    const handleDeleteDialogShow = () => {
        setShowDeleteDialog(true)
    }

    const handleDeleteDialogHide = () => {
        setShowDeleteDialog(false)
    }

    const handleShareDialogShow = () => {
        setShowShareDialog(true)
    }

    const handleShareDialogHide = () => {
        setShowShareDialog(false)
    }

    const handleFolderShareAdded = newMember => {
        onFolderShare({
            ...folder,
            sharedWith: folder.sharedWith.concat(newMember)
        })
    }

    const handleFolderShareRemoved = memberToDelete => {
        const indexToDelete = folder.sharedWith.findIndex(member => member.email === memberToDelete.email)
        
        onFolderShare({
            ...folder,
            sharedWith: [
                ...folder.sharedWith.slice(0, indexToDelete),
                ...folder.sharedWith.slice(indexToDelete + 1)
            ]
        })
    }

    const handleDeleteSubFolder = (deletedItem) => {
        const indexToDelete = folderItems.findIndex(folder => folder.name === deletedItem.name)

        setFolderItems([
            ...folderItems.slice(0, indexToDelete),
            ...folderItems.slice(indexToDelete + 1)
        ])
    }

    const folderItemsComponents = folderItems && folderItems.map(item => {
        if (item.type === 'file') {
            return <FileItem 
                item={item} 
                key={item.name} 
                level={level + 1} />
        } else {
            return <FolderItem 
                folder={item} 
                key={item.name} 
                level={level + 1} 
                onFolderDelete={() => handleDeleteSubFolder(item)} 
                onFolderShare={() => {}}/>
        }
    })

    return (
        <>
            <ListItem button disableRipple onClick={handleOpenFolder} className={classes.nested}>
                <ListItemIcon >
                    {loading ? <CircularProgress size={20} /> :
                    <FadeInOutIcon fadeIn={folderOpen} 
                        fadeInIсon={<FolderOpenIcon color='primary' fontSize='large' />} 
                        fadeOutIсon={<FolderClosedIcon color='primary' fontSize='large'/> } />
                    }
                </ListItemIcon>
                <ListItemText primary={folder.name} secondary={
                    <React.Fragment>
                        <Typography component="span" variant='body2' className='folder-date'>{toLocalDate(folder.uploadedTimestamp)}</Typography>
                    </React.Fragment>
                } className={classes.folderName} primaryTypographyProps={{variant: 'h6'}}/>
                
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={handleOpenFolder}>
                        {folderOpen ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                    <Tooltip title={t("Delete Folder")} placement="top">
                        <IconButton edge="end" aria-label="delete" onClick={handleDeleteDialogShow}>
                            <DeleteIcon color='primary' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Share Folder")} placement="top">
                        <IconButton edge="end" aria-label="share" onClick={handleShareDialogShow}>
                            <ShareIcon color='primary'/>
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={folderOpen} timeout="auto">
                <List component="div" disablePadding>
                    {folderItemsComponents}
                </List>
            </Collapse>

            <DeleteFolderDialog folder={folder} open={showDeleteDialog} onClose={handleDeleteDialogHide} onDelete={() => onFolderDelete(folder)}/>
            <ShareFolderDialog folder={folder} open={showShareDialog} onClose={handleShareDialogHide} onShare={handleFolderShareAdded} onUnshare={handleFolderShareRemoved}/>
        </>
    )
}

export default FolderItem