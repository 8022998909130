import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person'
import PersonRemoveIcon from '@material-ui/icons/PersonAddDisabled'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { shareFolder, unshareFolder } from '../../../api/fileShareApi'
import { useSnackbar } from 'notistack'
import { EMAIL_REGEXP } from '../utils/utils'

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1) / 2,
        top: theme.spacing(1) / 2,
        color: theme.palette.grey[500],
    },
    closeButtonBottom:{
        backgroundColor:'#F6F6F4',
        paddingLeft:'1rem',
        paddingRight:'1rem',
        color:'333',
        '&:hover':{
            backgroundColor:'#00B9F6',
            color:'white'
        }
    },
    progress: {
        display: 'flex',
        justifyContent: 'center'
    }
})

const getInitialMembers = (folder) => {
    // owner should be always on top
    return [{ email: folder.owner, owner: true }, ...folder.sharedWith]
}

const ShareFolder = ({ folder, open, onClose, onShare, onUnshare }) => {
    const classes = makeStyles(styles)()
    const { t } = useTranslation()
    const [progress, setProgress] = useState(false)
    const [error, setError] = useState('')
    const [members, setMembers] = useState(getInitialMembers(folder))
    const [newMember, setNewMember] = useState('')
    const { enqueueSnackbar } = useSnackbar()

    const handleCloseAction = () => {
        setProgress(false)
        onClose()
    }

    const handleChange = event => setNewMember(event.target.value)

    const validateForm = () => {
        if (!newMember){
            return false
        }

        if (!EMAIL_REGEXP.test(newMember)) {
            setError(t('Invalid email address'))
            return false
        }

        return true

    }

    const handleShareFolderFormSubmit = event => {
        event.preventDefault()

        if(!validateForm()) {
            return
        }

        setProgress(true)
        setError('')
        shareFolder(folder.path, newMember).then(success => {
            const newRecord = {email: newMember}
            setMembers(members.concat(newRecord))
            onShare(newRecord)

            // UI update
            setNewMember('')
            setError('')
            setProgress(false)
        }).catch(error => {
            setError(error.response.data.message)
            setProgress(false)
        })
    }

    const removeMemberFromUI = memberToRemove => {
        const indexToDelete = members.findIndex(member => !member.owner && member.email === memberToRemove.email)

        setMembers([
            ...members.slice(0, indexToDelete),
            ...members.slice(indexToDelete + 1)
        ])
    }

    const handleUnshareMember = memberToDelete => {
        setProgress(true)
        unshareFolder(folder.path, memberToDelete.email).then(success => {
            onUnshare(memberToDelete)

            // UI update
            removeMemberFromUI(memberToDelete)
            setProgress(false)
        }).catch(error => {
            if (error.response.data.code === '1009'){
                enqueueSnackbar( t('Folder cannot be unshared'), { variant: 'error' })
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' })
            }            
            setProgress(false)
        })
    }


    // rendering
    const membersList = <List dense subheader={
        <ListSubheader component="div" id="nested-list-subheader" disableGutters disableSticky color='primary'>
            {t('Members')}
        </ListSubheader>
    }>
        {members.map(member =>
            <ListItem key={member.email} disableGutters>
                <ListItemAvatar>
                    <Avatar><PersonIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={member.email} secondary={member.owner ? t('owner') : t('readonly')} />
                
                {!member.owner && <ListItemSecondaryAction>
                    <Tooltip title={t("Disable access")} placement="top">
                        <IconButton edge='end' onClick={() => handleUnshareMember(member)}>
                            <PersonRemoveIcon color='primary' />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>}
            </ListItem>
        )}
    </List>

    const membersContent = progress ? <div className={classes.progress}><CircularProgress size={72} /></div> : membersList

    return (
        <Dialog 
            onClose={handleCloseAction} 
            aria-labelledby="simple-dialog-title" 
            open={open} 
            maxWidth='sm'
            fullWidth={true}>

            <DialogTitle id="simple-dialog-title" disableTypography>
                <Typography variant='h5' color='primary'>
                    {t('Share')} "{folder.name}"
                </Typography>

                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseAction}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleShareFolderFormSubmit} >
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        name="email"
                        label={t('Add member (readonly)')}
                        type="email"
                        error={error !== ''}
                        helperText={error}
                        fullWidth
                        value={newMember}
                        onChange={handleChange}
                    />
                </form>
                {membersContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAction} className={classes.closeButtonBottom} >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShareFolder;