import React, { useState } from 'react'
import { AuthProvider } from './authContext';
import AuthService from '../../../utils/authService'
import { withRouter } from "react-router";

const Auth = ({children, history}) => {
    const authService = new AuthService(history)
    const [state, setState] = useState(authService)

    const handleAuthenticating = () => {
        setState(prevState => ({
            ...prevState,
            authenticating: true
        }))
    }

    const provider = {
        ...state,
        handleAuthenticating
    }

    return (
        <AuthProvider value={provider}>
            {children}
        </AuthProvider>
    )
}

export default withRouter(Auth)