import React from 'react'
import { Route } from 'react-router-dom'
import { useAuthContext } from './provider/authContext'

import NotLoggedIn from './NotLogedIn'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated} = useAuthContext()

    return <Route {...rest} render={props => (
        isAuthenticated() ? <Component {...props} /> : <NotLoggedIn />
    )} />
}

export default PrivateRoute;