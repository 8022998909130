export const transformResponseError = (data, headers) => {
    if (headers['Content-Type'].toLowerCase().indexOf('json') !== -1) {
        return JSON.parse(Buffer.from(data));
    }
    return data;
}

const RANGE_BLOCK_SIZE = 16 // Range block must refer to (a) specific block(s) multiple of 16 bytes
const DEFAULT_MAX_BLOCK_SIZE = 10 * 1024 * 1024 // Download block must be less then 100 MB

export const splitByRanges = (totalFileSize, minRangesCount, maxBlockSize = DEFAULT_MAX_BLOCK_SIZE) => {
    let rangesCount = minRangesCount
    let rangeSize = Math.floor(totalFileSize / (RANGE_BLOCK_SIZE * rangesCount))

    // recalculate range size if block size reaches limit
    if (rangeSize * RANGE_BLOCK_SIZE > maxBlockSize) {
        rangesCount = Math.floor(totalFileSize / maxBlockSize)
        rangeSize = Math.floor(totalFileSize / (rangesCount * RANGE_BLOCK_SIZE))
    }

    const ranges = Array(rangesCount).fill().map((val, index) => ({
        start: index * rangeSize * RANGE_BLOCK_SIZE, 
        end: (index + 1) * rangeSize * RANGE_BLOCK_SIZE - 1
    }))
    ranges[rangesCount-1].end = totalFileSize - 1
    
    return ranges
}

export const concatArrayBuffers = (buffers) => {
    let size = buffers.reduce((a, b) => a + b.byteLength, 0)

    let result = new Uint8Array(size)

    let offset = 0
    buffers.forEach(arr => {
        result.set(new Uint8Array(arr), offset)
        offset += arr.byteLength
    })

    return result
}